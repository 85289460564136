.project_detail_main_block {
  padding-top: 34px;
  width: 1564px;
  .project_detail_main {
    display: flex;
    margin-top: 40px;
    .project_detail_main_left {
      width: 1144px;
      padding-right: 48px;
      box-sizing: border-box;
      .project_detail_left_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .project_detail_left_info_content {
          display: flex;
          align-items: flex-start;
        }
        .project_detail_info_image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
        }
        .project_detail_info_text {
          margin-left: 20px;
          .project_detail_info_name {
            display: flex;
            align-items: center;
            font-weight: bold;
            font-size: 32px;
            color: var(--text-color);
            span {
              color: var(--pagination-total);
            }
            .project_detail_info_running {
              padding: 7px 11px 7px 7px;
              border-radius: 18px;
              border: 1px solid #A3DCAC;
              color: #23BB3D;
              font-weight: 500;
              font-size: 12px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 20px;
              img {
                margin-right: 3px;
              }
            }
          }
          .project_detail_info_context {
            font-weight: 400;
            font-size: 14px;
            color: var(--login-top-text);
            margin-top: 12px;
          }
          .project_detail_info_tag {
            display: flex;
            align-items: center;
            gap: 22px;
            margin-top: 15px;
            .project_detail_info_tag_item {
              display: flex;
              align-items: center;
              font-weight: 400;
              font-size: 12px;
              color: var(--text-color);
              cursor: pointer;
              img {
                width: 16px;
                height: 16px;
                margin-right: 2px;
              }
            }
          }
        }
      }
      .project_detail_left_handle {
        display: flex;
        align-items: center;
        .project_detail_left_handle_btn {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
          background: var(--handle-btn-back);
          margin-right: 37px;
        }
      }
    }
  }
}

.project_detail_line {
  height: 22px;
  width: 1px;
  background: var(--project-info-line);
}

.project_detail_good {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: 36px;
  color: var(--text-color-sec);
  cursor: pointer;
  img {
    width: 22px;
    height: 22px;
    margin-bottom: 8px;
  }
}
.project_detail_info_link_list {
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 24px;
  .project_detail_info_link {
    display: inline-flex;
    align-items: center;
    padding: 5px 13px 5px 5px;
    background: var(--search-back);
    border-radius: 10px;
    img {
      margin-right: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }
    &:hover {
      background: #F1E9FE;
      color: #911AD2;
    }
  }
}

.project_detail_text_info {
  display: flex;
  align-items: center;
  margin-top: 30px;
  span {
    color: var(--text-color-sec);
  }
  div {
    margin-left: 96px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.project_detail_details {
  margin-top: 73px;
  position: relative;
  .project_detail_details_title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 30px;
  }
  .project_detail_details_content {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: var(--text-color-sec);
    word-break: break-word;
  }
}

.project_form_team_list {
  display: grid;
  grid-template-columns: repeat(3,280px);
  grid-gap: 20px;
  margin-top: 17px;
  .project_form_team_list_item {
    height: 82px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 95px 0 8px;
    min-width: 166px;
    border: 1px solid var(--file-border);
    border-radius: 8px;
    background: var(--coin-back);
    color: var(--file-title);
    position: relative;
    font-size: 12px;
    .project_form_team_list_item_name {
      font-weight: 500;
      font-size: 14px;
      color: var(--text-color);
      margin-bottom: 9px;
    }
    .project_form_team_list_item_media {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 14px;
      bottom: 6px;
    }
  }
}

.project_investor_block {
  border-radius: 30px;
  padding: 10px;
  border: 1px solid var(--file-border);
  background: var(--message-type-block);
  display: flex;
  align-items: flex-start;
  margin-bottom: 24px;
  .project_investor_block_left {
    width: 220px;
    height: 246px;
    background: linear-gradient( 91deg, #FEEBF9 0%, #EEEFFF 100%);
    border-radius: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .project_investor_block_left_time {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      color: #5D5D5D;
      position: absolute;
      top: 14px;
      left: 22px;
      img {
        width: 11px;
        height: 11px;
        margin-right: 8px;
      }
    }
    .project_investor_block_left_price {
      font-weight: bold;
      font-size: 30px;
      color: #FF5353;
      margin-top: 80px;
    }
    .project_investor_block_left_series {
      font-weight: 400;
      font-size: 14px;
      color: #000000;
      margin-top: 10px;
    }
    .project_investor_block_left_link {
      display: flex;
      align-items: center;
      margin-top: 23px;
      font-weight: bold;
      font-size: 14px;
      color: #000000;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .project_investor_block_left_line {
      width: 71px;
      height: 1px;
      background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
      margin-top: 4px;
    }
  }
  .project_investor_block_right {
    margin-left: 30px;
    margin-top: 14px;
    color: var(--text-color);
    .project_investor_leadInvestor_list {
      margin-top: 20px;
      display: flex;
      gap: 10px;
      width: 800px;
      flex-wrap: wrap;
      .project_investor_leadInvestor_item {
        padding-left: 10px;
        padding-right: 40px;
        height: 80px;
        border: 1px solid var(--investor-border);
        border-radius: 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .project_investor_leadInvestor_image {
          width: 54px;
          height: 54px;
          margin-right: 13px;
          border-radius: 50%;
        }
        span {
          font-weight: bold;
          font-size: 14px;
          color: #5D5D5D;
        }
      }
    }
  }
}

.project_investor_block_right_bottom {
  padding-top: 12px;
}

.project_investor_tag_list {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 800px;
  flex-wrap: wrap;
  .project_investor_tag_item {
    padding: 0 45px;
    height: 43px;
    background: var(--search-back);
    color: var(--text-color-sec);
    border-radius: 10px;
    line-height: 43px;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
}

.project_form_bottom_table {
  padding: 0 10px;
  border-radius: 10px;
  .project_form_bottom_table_head_line {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    font-weight: bold;
    font-size: 14px;
    color: var(--text-color);
    grid-column-gap: 8px;
    margin-bottom: 22px;
  }
  .project_form_bottom_table_item_line {
    @extend .project_form_bottom_table_head_line;
    background: var(--coin-back);
    border-radius: 10px;
    border: 1px solid var(--investor-table-border);
    padding: 15px 0;
  }
  .project_form_bottom_table_head {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
  }
}

.project_form_bottom_table_item {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  .project_name_icon {
    width: 30px;
    height: 30px;
  }
  .project_link_icon {
    width: 16px;
    height: 16px;
  }
}

.project_form_bottom_table_head_sp {
  @extend .project_form_bottom_table_head;
  justify-content: flex-start !important;
  padding-left: 20px;
  box-sizing: border-box;
}

.project_overseas_table {
  width: 1060px;
}

.project_detail_details_review {
  width: 1086px;
  position: relative;
}

.project_review_item {
  width: 100%;
  border-bottom: 1px solid var(--project-review-border);
  .project_review_item_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .project_review_item_top_left {
      display: flex;
      align-items: center;
      .project_review_item_avatar {
        width: 46px;
        height: 46px;
        background: var(--project-review-avatar);
        text-align: center;
        line-height: 46px;
        border-radius: 50%;
        font-weight: bold;
        font-size: 16px;
        color: #2B2B2B;
        margin-right: 14px;
      }
      .project_review_username {
        font-weight: bold;
        font-size: 14px;
        color: var(--text-color);
      }
      .project_review_date {
        font-weight: 400;
        font-size: 12px;
        color: var(--text-color-sec);
        margin-top: 3px;
      }
    }
  }
}

.project_review_item_top_right {
  display: flex;
  align-items: center;
  gap: 46px;
  .project_review_item_like {
    display: flex;
    align-items: center;
    flex-direction: column;
    span {
      font-weight: 400;
      font-size: 12px;
      color: var(--text-color-sec);
      margin-top: 8px;
    }
    img {
      width: 22px;
      height: 22px;
      margin-top: 22px;
    }
  }
}

.project_review_text {
  font-weight: 400;
  font-size: 16px;
  color: var(--project-review-text);
  padding: 40px 0 20px;
}

.project_review_load_btn {
  margin: 60px auto 65px;
  padding: 15px 20px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: inline-flex;
  background: var(--project-review-load-back);
  font-weight: 400;
  font-size: 12px;
  color: var(--text-color-sec);
  border-radius: 24px;
  cursor: pointer;
}

.project_detail_main_right {
  .project_detail_contributor {
    width: 420px;
    background: var(--project-detail-contributor-back);
    border-radius: 20px;
    padding-bottom: 30px;
  }
}

.project_detail_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 12px 16px;
  width: 100%;
  box-sizing: border-box;
  height: 54px;
  background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
  border-radius: 20px 20px 0 0;
  img {
    width: 4px;
    height: 8px;
    margin-left: 6px;
  }
  span {
    color: #FFFFFF !important;
  }
}

.project_contributor_avatar {
  display: flex;
  align-items: center;
  margin: 20px 30px 18px;
  div {
    font-weight: bold;
    font-size: 32px;
    color: var(--text-color);
  }
  img {
    width: 60px;
    height: 60px;
    margin-right: 12px;
  }
}

.project_contributor_msg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 380px;
  height: 70px;
  background: var(--login-input-back);
  border-radius: 10px;
  margin: 0 auto 10px;
  padding: 0 9px;
  box-sizing: border-box;
  .project_contributor_msg_title {
    font-weight: bold;
    font-size: 14px;
    color: var(--text-color);
  }
  .project_contributor_msg_key {
    font-family: DIN;
    font-weight: 500;
    font-size: 12px;
    width: 210px;
    text-align: right;
    word-break: break-word;
  }
}

.project_contributor_project {
  display: flex;
  align-items: center;
  width: 380px;
  height: 64px;
  background: var(--login-input-back);
  border-radius: 10px;
  margin: 0 auto 10px;
  padding: 0 9px;
  box-sizing: border-box;
  img {
    width: 42px;
    height: 42px;
    margin-right: 10px;
    border-radius: 50%;
  }
  .project_contributor_project_title {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .project_contributor_project_text {
    font-weight: 400;
    font-size: 12px;
    color: var(--text-color-sec);
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 280px;
  }
}

.project_detail_details_btn {
  padding: 12px 26px 12px 11px;
  background: var(--search-back);
  border-radius: 6px;
  border: 1px solid var(--comment-btn-border);
  display: inline-flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  gap: 13px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.project_comment_dialog {
  display: flex;
  flex-direction: column;
  width: 600px;
  background: var(--tag-btn-border);
  border-radius: 30px;
  border: 1px solid #707070;
  position: relative;
  .project_comment_dialog_close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .project_comment_dialog_title {
    width: 100%;
    padding: 23px 0 21px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    color: #000000;
  }
}

.project_textarea {
  width: 550px;
  height: 162px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 14px;
}

.project_textarea:hover {
  background: var(--project-textarea-back);
}

.project_handle_btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  gap: 50px;
  div {
    padding: 20px 30px;
    background: var(--project-dialog-cancle-back);
    color: #FFFFFF;
    border-radius: 12px;
    cursor: pointer;
  }
  .project_handle_btn_confirm {
    background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
  }
}

.project_detail_update_block {
  width: 524px;
  margin: 0 auto;
}

.project_detail_update_btn_list {
  display: flex;
  align-items: center;
  gap: 10px;
  div {
    padding: 18px 15px 15px;
    background: var(--project-update-btn-back);
    border: 1px solid var(--project-update-btn-border);
    border-radius: 10px;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    &:hover {
      background: #DAA8E7;
      color: #A327C3;
    }
  }
}

.project_detail_update_small_title {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-bottom: 10px;
}

.project_detail_update_text {
  font-weight: 500;
  font-size: 12px;
  color: var(--project-update-text);
  line-height: 20px;
  padding: 25px 0 80px;
}
