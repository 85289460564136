.project_list_main_view {
  width: 100%;
  display: flex;
  .project_list_left_menu {
    border-right: 1px solid var(--project-left-border);
    padding: 25px 23px 80px 36px ;
    height: 956px;
    overflow-y: hidden;
    box-sizing: border-box;
    &:hover {
      overflow-y: scroll;
    }
    .project_list_left_menu_top_grid {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      grid-row-gap: 8px;
      grid-column-gap: 8px;
      .project_list_left_menu_top_grid_item {
        padding: 0 35px;
        height: 48px;
        border: 1px solid var(--project-left-top-sort-border);
        background: var(--project-left-top-sort-back);
        border-radius: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
        font-size: 12px;
        img {
          width: 8px;
          height: 15px;
          margin-right: 9px;
        }
      }
    }
  }
  .project_list_right_table {
    flex: 1;
  }
}

.project_list_tag {
  font-weight: 500;
  font-size: 18px;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-collapse>.ant-collapse-item >.ant-collapse-header {
  padding: 14px 0;
}

.ant-collapse .ant-collapse-content>.ant-collapse-content-box {
  padding: 16px 0;
}

.project_form_input {
  height: 40px;
}

.project_list_radio {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 26px;
  span {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color);
  }
  img {
    width: 18px;
    height: 18px;
  }
}

.included_radio_check_project {
  max-height: 354px;
  overflow-y: hidden;
  &:hover {
    overflow-y: scroll;
  }
}

.project_list_right_table_block {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  padding-left: 50px;
  padding-right: 150px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.project_table_item {
  height: 134px;
  box-sizing: border-box;
  box-shadow: 0 3px 20px 1px rgba(168,168,168,0.16);
  border-radius: 20px;
  border: 1px solid var(--project-table-item);
  padding: 13px 17px;
  cursor: pointer;
  position: relative;
  .project_table_item_top {
    display: flex;
    img {
      width: 58px;
      height: 58px;
      border-radius: 50%;
    }
    .project_table_item_text {
      margin-left: 17px;
      .project_table_item_title {
        font-weight: bold;
        font-size: 16px;
        color: var(--text-color);
        margin-top: 1px;
        width: 210px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
      .project_table_item_content {
        color: var(--login-top-text);
        font-weight: 500;
        font-size: 12px;
        margin-top: 9px;
        white-space: nowrap;
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .project_table_item_bottom {
    display: flex;
    align-items: center;
    margin-top: 18px;
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
    gap: 10px;
    .project_table_item_bottom_tool {
      padding: 5px 25px;
      border: 1px solid var(--project-table-tool-border);
      border-radius: 6px;
      background: var(--project-table-tool-back);
    }
    .project_table_item_bottom_data {
      padding: 5px 18px;
      border: 1px solid var(--project-table-tool-border);
      border-radius: 6px;
      margin-left: 10px;
    }
  }
}

.project_table_page {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 35px;
}

.project_tag_list {
  display: flex;
  align-items: center;
  padding: 18px 0 0 53px;
  gap: 14px;
  .project_tag_list_clear_btn {
    padding: 8px 20px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid var(--clear-all-btn-border);
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    background: var(--clear-all-btn-back);
  }
  .project_tag_list_clear_btn_tag {
    padding: 8px 10px 8px 20px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid var(--tag-btn-border);
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    background: var(--tag-btn-back);
    img {
      margin-left: 18px;
    }
  }
}
