.canvas_back {
    background-color: var(--back-color);
}

.home_top_title {
    margin: 48px auto 43px;
    width: 700px;
    text-align: center;
    font-size: 180px;
    line-height: 130px;
    background:-webkit-linear-gradient(135deg, #9C20C9 0%, #FD806B 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    white-space: nowrap;
}

.home_top_text {
    width: 1327px;
    height: 188px;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: var(--text-color);
}

.home_top_text > div {
    margin-bottom: 30px;
}

.home_middle_logo_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_middle_logo {
    margin: 0 auto;
    text-align: center;
}

.home_middle_roadmap_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin-bottom: 93px;
    padding-top: 10px;
}

.home_middle_roadmap_title {
    font-size: 140px;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 10px;
    text-align: center;
    margin-top: 66px;
}

.home_roadmap_block {
    position: relative;
}

.home_roadmap_mobile {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 4;
}

.home_checkMap {
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    border-radius: 16px;
    color: #FFFFFF;
    width: 1816px;
    height: 200px;
    position: absolute;
    left: 0;
    z-index: 2;
    transition: top 0.2s;
}

.home_map {
    width: 1816px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 100px;
    box-sizing: border-box;
    position: relative;
    z-index: 5;
}

.home_map_title {
    font-size: 36px;
    font-weight: bold;
    color: var(--text-color);
    line-height: 58px;
    width: 410px;
}

.home_map_check > .home_map_title{
    color: #FFFFFF !important;
}

.home_map_text {
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 38px;
    width: 562px;
}

.home_map_state {
    position: absolute;
    top: 0;
    left: 0;
    background: #A225C3;
    border-radius: 16px 0 30px 0;
    color: #FFC8C8;
    width: 158px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

.home_invite_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home_invite_title {
    font-size: 140px;
    font-weight: bold;
    color: var(--text-color);
    line-height: 140px;
    text-align: center;
}

.home_middle_back_img {
    position: absolute;
    top: -90px;
    left: 426px;
}

.home_referral_title {
    color: #B63BB2;
    margin-left: 50px;
}

.home_invite_v {
    width: 90px;
    height: 90px;
    margin-left: 10px;
}

.home_invite_system {
    display: flex;
    align-items: center;
    justify-content: center;
}

.home_invite_main_block {
    display: flex;
    align-content: center;
    margin-top: 76px;
}

.home_invite_main_left {
    display: flex;
    align-items: center;
    margin-right: 86px;
}

.home_invite_main_right {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 846px;
}

.home_invite_text {
    font-size: 24px;
    font-weight: 400;
    color: var(--text-color-sec);
    line-height: 38px;
    width: 100%;
}

.home_invite_data {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 164px;
}

.home_invited_data_title {
    font-size: 30px;
    font-weight: bold;
    color: var(--text-color);
    text-align: center;
}

.home_invited_data_title > .dataNumber {
    background:-webkit-linear-gradient(135deg, #9C20C9 0%, #FD806B 100%);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    height: 85px;
    font-size: 85px;
    line-height: 85px;
    margin-bottom: 50px;
}

.home_invite_data_scroll {
    height: 102px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 64px;
}

.home_invite_middle_inline {
    width: calc(100% - 12px);
    height: 4px;
    background-color: #CDBDE5;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
}

.home_line_dot {
    width: 20px;
    height: 20px;
    background: #CDBDE5;
    border-radius: 50%;
    position: absolute;
}

.home_invite_line_left_dot {
    left: 0;
}

.home_invite_line_right_dot {
    right: 0
}

.home_line_scroll_image {
    width: 102px;
    height: 102px;
    position: absolute;
    left: 0;
    transition: left 0.2s;
    cursor: pointer;
    background: url("../../assets/image/home/home_line_scroll.png") no-repeat;
}

.home_invite_left_main {
    width: 398px;
    height: 572px;
    position: relative;
    margin-left: 30px;
}

.home_invite_main_pic {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.home_line_arrowhead {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 14px 0 40px;
}

.home_line_Button {
    display: flex;
    width: 100%;
}

#home_line_button {
    width: 205px;
    height: 64px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    border: none;
    border-radius: 45px;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
}

.home_invite_main_pic_p1,
.home_invite_main_pic_p2,
.home_invite_main_pic_p3,
.home_invite_main_pic_p4 {
    position: absolute;
    z-index: 3;
}

.home_middle_swiper {
    width: 100%;
    height: 77px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-bottom: 60px;
}

.home_middle_swiper_logo {
    width: 206px;
    height: 52px;
    margin-left: 125px;
    margin-right: 27px;
    animation: home_middle_scroll 10s linear infinite;
}

@keyframes home_middle_scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-357px);
    }
}

.home_investment_block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.home_investment_title {
    font-size: 130px;
    line-height: 130px;
    font-weight: bold;
    color: var(--text-color);
}

.home_investment_color_text > span:first-child {
    color: #F67A73;
}

.home_investment_color_text > span:last-child {
    color: #B439B3;
}

.home_investment_text {
    font-size: 24px;
    font-weight: 400;
    color: var(--text-color-sec);
    line-height: 38px;
    width: 1100px;
    margin: 50px 0 23px;
}

.home_money_package {
    width: 616px;
    height: 522px;
    position: relative;
    border-radius: 50%;
    border: 1px dashed #B8B8B8;
}

.home_money_package_image {
    width: 322px;
    height: 232px;
    position: absolute;
    bottom: 84px;
    left: 172px;
    z-index: 10;
}

.home_invest_icon {
    position: absolute;
    top: 280px;
    left: 260px;
    z-index: 9;
}

.home_big_banner {
    width: 100%;
    margin-bottom: 120px;
    position: relative;
    padding-top: 94px;
    overflow: hidden;
}

.home_big_banner_swiper {
    height: 740px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    border-radius: 60px;
    position: absolute;
    font-size: 490px;
    line-height: 740px;
    padding: 0 156px;
    color: #ffffff;
}

.home_swiper_overflow {
    width: 100%;
    height: 740px;
    overflow: hidden;
}

.home_platform_block {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.home_platform_title {
    font-size: 100px;
    font-weight: bold;
    color: var(--text-color);
    margin-bottom: 55px;
}

.home_values_grid {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 100px;
}

.home_values_grid_block {
    width: 518px;
    height: 670px;
    background: var(--value-back);
    border-radius: 16px;
    padding: 70px 33px 32px;
    box-sizing: border-box;
}

.home_values_title {
    height: 84px;
    width: 100%;
    font-size: 34px;
    font-weight: bold;
    color: var(--text-color);
}

.home_values_image {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 246px;
}

.home_values_text {
    width: 100%;
    height: 173px;
    font-size: 24px;
    font-weight: 400;
    color: var(--text-color-sec);
    line-height: 38px;
    margin-top: 57px;
}

.home_bottom_block {
    width: 100%;
    background: linear-gradient(182deg, #FD806B 0%, #9C20C9 100%);
    border-radius: 60px 60px 0 0;
    padding-bottom: 44px;
}

.home_bottom_title {
    font-size: 94px;
    font-weight: 400;
    color: #FFFFFF;
    width: 100%;
    text-align: center;
    padding-top: 80px;
}

.home_bottom_text {
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 48px;
    width: 1024px;
    text-align: center;
    margin: 28px auto 68px;
}

.home_bottom_middle {
    width: 100%;
    height: 600px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.home_bottom_middle > img{
    width: 513px;
    height: 187px;
}

.home_bottom_middle_block {
    flex: 1;
    height: 540px;
    margin-bottom: 60px;
    border-radius: 60px;
    border: 2px solid #FFFFFF;
}

.home_footer_block {
    width: 1733px;
    margin: 0 auto;
}

.home_footer_top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 300px;
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 48px;
}

.home_footer_top_left {
    font-size: 48px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 48px;
}
.home_footer_top_left > div:last-child {
    font-size: 20px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    opacity: 0.5;
}

.home_footer_top_right {
    display: flex;
    align-items: center;
}

#home_footer_input {
    width: 425px;
    height: 60px;
    background: #FFFFFF;
    box-shadow: 0 3px 6px 1px rgba(142,142,142,0.16);
    border-radius: 46px;
    padding-left: 35px;
    font-size: 16px;
    font-weight: 400;
}

#home_footer_button{
    width: 180px;
    height: 56px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    font-size: 18px;
    font-weight: bold;
    color: #FFFFFF;
    margin-left: 8px;
    border: none;
    border-radius: 30px;
}

.home_footer_bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 94px;
}

.home_bottom_logo {
    width: 260px;
    height: 58px;
    margin-bottom: 13px;
}

.home_join_community {
    font-size: 18px;
    font-weight: 400;
    color: #F9C2FF;
    line-height: 26px;
}

.home_footer_english {
    margin-top: 40px;
}

.home_footer_chinese {
    margin-top: 33px;
}

.home_footer_community_title {
    font-size: 14px;
    font-weight: 400;
    color: #F9C2FF;
    line-height: 26px;
    margin-bottom: 16px;
}

.home_community_icon {
    display: flex;
    align-items: center;
    gap: 20px;
}

.home_community_icon > img {
    width: 34px;
    height: 34px;
}

.home_bottom_copyright {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255,255,255,0.52);
    line-height: 26px;
    margin-top: 64px;
}

.home_footer_right {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.home_footer_right_title {
    font-size: 16px;
    font-weight: bold;
    color: #FFFFFF;
    margin-bottom: 30px;
}

.home_footer_qrcode {
    width: 120px;
    height: 120px;
}

.home_footer_about {
    margin-right: 150px;
}

.home_footer_entryInclusion {
    margin-right: 280px;
}

.home_footer_right_text {
    margin-bottom: 30px;
    font-size: 14px;
    font-weight: 400;
    color: #DB6FEE;
    cursor: pointer;
}

.home_bottom_middle_img {
    width: 149px;
    height: 149px;
    border-radius: 50%;
    overflow: hidden;
}

.home_bottom_middle_img > img {
    width: 100%;
    height: 100%;
}

.home_Popover_text {
    padding: 6px 0;
    font-weight: 500;
    font-family: Montserrat,'PingFang',sans-serif;
    color: #0f1114;
}
