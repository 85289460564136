.timeLine_block {
  width: 540px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .timeLine_line {
    display: flex;
    flex-direction: column;
    align-items: center;
    .timeLine_line_item {
      height: 2px;
      width: 8px;
      background: #DFDFDF;
      margin-bottom: 8px;
    }
    .timeLine_line_item_long {
      @extend .timeLine_line_item;
      width: 14px;
    }
  }
}

.timeLine_main {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  width: 100%;
  .timeLine_main_flex {
    display: flex;
    align-items: center;
    width: 100%;
    height: 110px;
    .timeLine_main_block {
      border-top: 1px dashed var(--time-line-border);
      border-bottom: 1px dashed var(--time-line-border);
      width: 264px;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .timeLine_main_block_time {
        font-weight: 400;
        font-size: 14px;
        color: var(--time-line-time-color);
      }
      .timeLine_main_block_text {
        margin-top: 2px;
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        img {
          margin-right: 5px;
        }
      }
    }
  }
}
