.pagination_handle_arrow_render {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .pagination_handle_arrow {
    width: 19px;
    height: 19px;
  }
}
.ant-pagination .ant-pagination-total-text {
  height: 38px;
}
.pagination_handle_total {
  font-size: 12px;
  font-weight: 400;
  color: var(--pagination-total);
  font-family: Montserrat,'PingFang',sans-serif !important;
  height: 38px;
  line-height: 38px;
}

.pagination_handle_button {
  border-radius: 2px;
  box-sizing: border-box;
  width: 38px;
  height: 38px;
  line-height: 38px;
}

.ant-pagination .ant-pagination-item-active:hover {
  border: none !important;
}

.ant-pagination .ant-pagination-item ,.ant-pagination .ant-pagination-jump-prev,.ant-pagination .ant-pagination-jump-next{
  background: var(--pagination-number-back);
  font-family: Montserrat,'PingFang',sans-serif !important;
  font-weight: 400;
  min-width: 38px;
  height: 38px;
  line-height: 38px;
  color: var(--pagination-number-text) !important;
}

.ant-pagination .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: var(--pagination-number-text) !important;
}

.ant-pagination .ant-pagination-item-active {
  border: none !important;
  background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
  color: #ffffff !important;
}

.ant-pagination-options-quick-jumper {
  font-family: Montserrat,'PingFang',sans-serif !important;
  color: var(--pagination-total);
}
.ant-pagination .ant-pagination-options-quick-jumper input {
  width: 46px;
  height: 28px;
  background: var(--pagination-number-back) !important;
  border: 1px solid #DCDFE6 !important;
  color: var(--text-color);
}
