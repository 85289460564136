.lang_block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 50px;
  div {
    width: 96px;
    height: 48px;
    border-radius: 10px;
    text-align: center;
    line-height: 48px;
    font-weight: bold;
    font-size: 14px;
    background: var(--lang-btn);
    color: var(--text-color);
    cursor: pointer;
  }
  .lang_block_check {
    background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
    color: #FFFFFF;
  }
}
