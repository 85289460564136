.radio_no_check {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--form-input-textarea);
  border: 1px solid var(--radio-border);
  cursor: pointer;
}

.radio_no_check:hover {
  border-color: #A327C3;
  background: #FAE6FF;
}

.radio_item {
  display: flex;
  align-items: center;
  cursor: pointer;
  .radio_text {
    font-size: 12px;
    font-weight: 500;
    color: #5D5D5D;
  }
  .radio_label {
    font-size: 14px;
    font-weight: 500;
    color: var(--radio-label);
    margin: 0 4px 0 8px;
  }
  img {
    width: 18px;
    height: 18px;
  }
}

.radio_list {
  display: flex;
  align-items: center;
  gap: 88px;
}
