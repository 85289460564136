.modal_dialog_main {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  background: rgba(0,0,0,.8);
  .modal_dialog_block {
    margin: 262px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
