.pageHeader_block {
    width: 100%;
    height: 44px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
}

.pageHeader_block_inline {
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.pageHeader_text {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-right: 128px;
    animation: scroll 10s linear infinite;
    position: relative;
    left: 20px;
    font-weight: bold;
    color: #000000;
}

.pageHeader_text > img {
    margin-right: 4px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-523px);
    }
}

.pageHeader_navi {
    width: 100%;
    height: 82px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.pageHeader_navi_main_color {
    background-color: var(--back-color);
}

.pageHeader_navi_other_color {
    background-color: var(--other-back-color);
}

.pageHeader_navi_left {
    display: flex;
    align-items: center;
    padding-left: 83px;
}

.pageHeader_navi_logo {
    width: 120px;
    height: 38px;
    margin-right: 15px;
}

.pageHeader_navi_text {
    margin-left: 48px;
    font-weight: 500;
    cursor: pointer;
    color: var(--text-color);
}

#pageHeader_navi_input {
    width: 269px;
    height: 40px;
    background: var(--search-back);
    border: 1px solid var(--search-border);
    text-align: center;
    margin-right: 22px;
}
#pageHeader_navi_input::placeholder {
    color: var(--search-placeholder);
}

.pageHeader_navi_right {
    //margin-right: 75px;
    display: flex;
    align-items: center;
}

.pageHeader_navi_mode_switch {
    width: 30px;
    height: 14px;
    background: var(--swicht-back);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    margin-left: 13px;
}

#pageHeader_navi_button {
    width: 100px;
    height: 40px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    font-size: 15px;
    color: #FFFFFF;
    border: none;
    border-radius: 8px;
}

.pageHeader_navi_downLine {
    width: 1772px;
    height: 1px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.pageHeader_navi_other_downLine {
    width: 100%;
    height: 1px;
    background: var(--project-left-border);
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
}

.pageHeader_sun_white {
    margin-left: -3px;
}

.pageHeader_sun_night {
    margin-left: 3px;
}

.pageHeader_night_white {
    margin-right: 3px;
}

.pageHeader_night_night {
    margin-right: -3px;
}

.pageHeader_navi_lang {
    margin-left: 22px;
    cursor: pointer;
}

.pageHeader_navi_lang_text {
    display: flex;
    align-items: center;
}

.pageHeader_fixed {
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
}

.pageHeader_message_icon {
    width: 16px;
    height: 16px;
}

.pageHeader_navi_block {
    width: 1772px;
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
}

.pageHeader_avatar {
    margin: 0 70px 0 43px;
}

.pageHeader_login {
    margin-left: 84px;
}

.pageHeader_avatar_image {
    width: 60px;
    height: 60px;
    background: #E9E9E9;
    border-radius: 50%;
    text-align: center;
    line-height: 60px;
    font-weight: bold;
    font-size: 18px;
    color: #2B2B2B;
    font-family: PingFang;
}

.pageHeader_title_address {
    width: 100%;
    text-align: center;
    font-weight: 400;
    .pageHeader_title_address_text {
        font-weight: bold;
        font-size: 16px;
    }
}

.pageHeader_title_body {
    width: 100%;
    border-top: 1px solid #EEEEEE;
    padding: 10px 0 0;
    .pageHeader_title_timeZone {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.pageHeader_title_left {
    font-weight: bold;
}

.pageHeader_logout {
    width: 100%;
    height: 40px;
    margin-top: 10px;
    box-sizing: border-box;
    background: #565656;
    border-radius: 6px;
    color: #FFFFFF;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
}
