.page_footer_block {
  width: 100%;
  margin: 0 auto;
  padding: 0 77px 0 108px;
  box-sizing: border-box;
  background: var(--pageFooter-bottom-back);
  position: absolute;
  bottom: 0;
}

.page_footer_top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 34px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 48px;
}

.page_footer_top_left {
  font-size: 48px;
  font-weight: bold;
  color: var(--text-color);
  line-height: 48px;
}

.page_footer_top_left > div:last-child {
  font-size: 20px;
  font-weight: 400;
  color: var(--pageFooter-bottom-text);
  line-height: 36px;
}

.page_footer_top_right {
  display: flex;
  align-items: center;
}

#page_footer_input {
  width: 425px;
  height: 60px;
  background: var(--login-input-back);
  box-shadow: 0 3px 6px 1px rgba(142,142,142,0.16);
  border-radius: 46px;
  padding-left: 35px;
  font-size: 16px;
  font-weight: 400;
  color: var(--text-color);
  &::placeholder {
    color: var(--pageFooter-bottom-placeholder);
  }
}

#page_footer_button{
  width: 180px;
  height: 56px;
  background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  margin-left: 8px;
  border: none;
  border-radius: 30px;
}

.page_footer_bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 42px;
}

.page_bottom_logo {
  width: 260px;
  height: 58px;
  margin-bottom: 13px;
}

.page_join_community {
  font-size: 18px;
  font-weight: 400;
  color: var(--text-color);
  line-height: 26px;
}

.page_footer_english {
  margin-top: 40px;
}

.page_footer_chinese {
  margin-top: 33px;
}

.page_footer_community_title {
  font-size: 14px;
  font-weight: 400;
  color: var(--pageFooter-bottom-text);
  line-height: 26px;
  margin-bottom: 16px;
}

.page_community_icon {
  display: flex;
  align-items: center;
  gap: 20px;
}

.page_community_icon > img {
  width: 34px;
  height: 34px;
}

.page_bottom_copyright {
  font-size: 12px;
  font-weight: 400;
  color: var(--page_bottom_copyright);
  line-height: 26px;
  margin-top: 64px;
}

.page_footer_right {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.page_footer_right_title {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 30px;
}

.page_footer_qrcode {
  width: 120px;
  height: 120px;
}

.page_footer_about {
  margin-right: 150px;
}

.page_footer_entryInclusion {
  margin-right: 280px;
}

.page_footer_right_text {
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
  color: var(--pageFooter-bottom-text);
  cursor: pointer;
}
