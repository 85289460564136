.meeting_list_block {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .meeting_list_title {
    font-weight: bold;
    font-size: 60px;
    padding: 40px 0 30px;
    color: var(--text-color);
  }
  .meeting_list_text {
    width: 1160px;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: var(--login-top-text);
    padding-bottom: 70px;
  }
}

.meeting_list_popular_figures {
  width: 100%;
  max-width: 1920px;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  display: flex;
  .meeting_list_arrow {
    width: 56px;
    height: 56px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
    &:first-child {
      left: 128px;
    }
    &:last-of-type {
      right: 128px;
    }
  }

  .meeting_list_popular_figures_item {
    width: 387px;
    display: inline-flex;
    flex-direction: column;
    border-radius: 12px;
    margin-right: 20px;
    position: relative;
    transform: translateX(-250px);
    transition: left 1s;
    background: var(--login-connect-back);
    border: 1px solid var(--meeting-border);
    cursor: pointer;
    .meeting_list_popular_figures_item_img {
      width: 100%;
      height: 200px;
      margin-bottom: 15px;
    }
    .meeting_list_popular_figures_item_name {
      width: 100%;
      font-weight: bold;
      font-size: 16px;
      color: var(--text-color);
      padding: 10px 20px 20px;
      line-height: 30px;
      text-align: left;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
    .meeting_list_popular_figures_item_time {
      display: flex;
      align-items: center;
      padding: 0 20px 25px;
      font-weight: 400;
      font-size: 12px;
      color: var(--text-color-sec);
      img {
        margin-right: 8px;
      }
    }
  }
}

.meeting_hot_icon {
  position: absolute;
  right: 0;
  top: 0;
}

.meeting_hot_border {
  border: 1px solid #555C66;
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 15px;
}

.meeting_list_popular_figures_dark_back {
  background: #FAE6FF !important;
  .meeting_list_popular_figures_item_name {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 18px;
    color: #000000 !important;
  }
  .meeting_list_popular_figures_item_tag {
    font-weight: bold;
    font-size: 14px;
    color: #B034B7;
    margin-bottom: 20px;
  }
  .meeting_list_popular_figures_item_tag2 {
    padding: 6px 17px;
    background: #EA9BFF !important;
    border-radius: 15px;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF !important;
  }
}

.meeting_list_popular_figures_block {
  padding-bottom: 13px;
  .meeting_list_popular_figures_title {
    font-weight: bold;
    font-size: 18px;
    margin-left: 156px;
  }
}

.meeting_list_left_modal {
  width: 123px;
  height: 100%;
  background: linear-gradient( 90deg, var(--back-color) 0%, rgba(255,255,255,0) 100%);
  position: absolute;
  left: 0;
  z-index: 8;
}

.meeting_list_right_modal {
  width: 123px;
  height: 100%;
  background: linear-gradient( 90deg, rgba(255,255,255,0) 0%, var(--back-color) 100%);
  position: absolute;
  right: 0;
  z-index: 8;
}

.meeting_list_character_block {
  margin-top: 30px;
  .meeting_list_character_main {
    width: 1608px;

    .meeting_list_popular_figures_item {
      width: 387px;
      display: inline-flex;
      flex-direction: column;
      border-radius: 12px;
      margin-right: 20px;
      position: relative;
      transition: left 1s;
      background: var(--login-connect-back);
      border: 1px solid var(--meeting-border);
      cursor: pointer;
      .meeting_list_popular_figures_item_img {
        width: 100%;
        height: 200px;
        margin-bottom: 15px;
      }
      .meeting_list_popular_figures_item_name {
        width: 100%;
        font-weight: bold;
        font-size: 16px;
        color: var(--text-color);
        padding: 10px 20px 20px;
        line-height: 30px;
        text-align: left;
        box-sizing: border-box;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow-x: hidden;
      }
      .meeting_list_popular_figures_item_time {
        display: flex;
        align-items: center;
        padding: 0 20px 25px;
        font-weight: 400;
        font-size: 12px;
        color: var(--text-color-sec);
        img {
          margin-right: 8px;
        }
      }
    }
  }
}

.meeting_list_character_sort {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0 20px;
  .meeting_list_character_sort_block {
    display: flex;
    align-items: center;
    .meeting_list_character_sort_left_block {
      padding: 7px 16px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      border: 1px solid var(--investors-tag-border);
      font-weight: 400;
      font-size: 14px;
      margin-right: 20px;
      cursor: pointer;
      img {
        width: 8px;
        height: 15px;
        margin-right: 7px;
      }
    }
    .meeting_list_character_sort_right_block {
      padding: 7px 12px 7px 14px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      border: 1px solid var(--investors-tag-border);
      font-weight: 400;
      font-size: 14px;
      margin-left: 20px;
      cursor: pointer;
      img {
        width: 10px;
        height: 6px;
        margin-left: 12px;
      }
    }
    .project_list_left_menu_top_grid_item {
      padding: 0 19px 0 17px;
      height: 48px;
      border: 1px solid var(--project-left-top-sort-border);
      background: var(--project-left-top-sort-back);
      border-radius: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 500;
      font-size: 12px;
      margin-right: 20px;
      img {
        width: 8px;
        height: 15px;
        margin-right: 9px;
      }
    }
  }
}

.meeting_list_character_list {
  display: grid;
  grid-template-columns: repeat(4,387px);
  justify-content: space-between;
  grid-row-gap: 20px;
  grid-column-gap: 26px;
  .meeting_list_character_list_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 246px;
    border: 1px solid var(--people-border);
    box-shadow: 0 0 20px 1px rgba(167,167,167,0.16);
    border-radius: 12px;
    padding: 20px 0;
    background: var(--login-connect-back);
    cursor: pointer;
    .meeting_list_popular_figures_item_img {
      width: 120px;
      height: 120px;
      margin-bottom: 15px;
    }
    .meeting_list_popular_figures_item_name {
      font-weight: bold;
      font-size: 16px;
      color: var(--text-color);
    }
    .meeting_list_popular_figures_item_video_img {
      padding: 8px 0 15px;
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 34px;
        height: 34px;
      }
    }
    .meeting_list_popular_figures_item_tag {
      font-weight: bold;
      font-size: 14px;
      color: #B034B7;
      margin-bottom: 8px;
    }
    .meeting_list_popular_figures_item_tag2 {
      padding: 6px 17px;
      background: var(--people-tag-back);
      border-radius: 15px;
      font-weight: 400;
      font-size: 14px;
      color: var(--people-tag-color);
    }
  }
}

.meeting_page_block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
}
