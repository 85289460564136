.router_line_block {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color);
  }
  .router_line_block_color {
    cursor: pointer;
    color: var(--router-line-color);
  }
}
