.more_main_block {
  display: flex;
  width: 100%;
  .more_main_block_left {
    width: 340px;
    height: 967px;
    border-right: 1px solid var(--project-left-border);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 26px;
    box-sizing: border-box;
    div {
      width: 264px;
      height: 40px;
      color: var(--text-color);
      border-radius: 10px;
      margin-bottom: 17px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding-left: 18px;
      box-sizing: border-box;
    }
    .more_main_block_check_left {
      background: var(--more-tag-check-back);
      color: #B034B7;
    }
  }
}

.more_child_block {
  padding: 13px 155px 54px 50px;
  box-sizing: border-box;
}

.more_tag_check {
    padding: 8px 10px 6px 14px;
    border: 1px solid var(--investors-tag-border);
    border-radius: 6px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 10px;
      height: 6px;
      margin-left: 8px;
    }
}

.more_child_sort_btn {
  display: flex;
  align-items: center;
  .more_child_left_menu_top_grid_item {
    padding-left: 17px;
    padding-right: 24px;
    height: 48px;
    border: 1px solid var(--project-left-top-sort-border);
    background: var(--project-left-top-sort-back);
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    margin-left: 8px;
    img {
      width: 8px;
      height: 15px;
      margin-right: 9px;
    }
  }
  .project_list_left_menu_top_grid_item {
    padding: 0 19px 0 17px;
    height: 48px;
    border: 1px solid var(--project-left-top-sort-border);
    background: var(--project-left-top-sort-back);
    border-radius: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    margin-right: 20px;
    img {
      width: 8px;
      height: 15px;
      margin-right: 9px;
    }
  }
}

.more_main_block_right {
  flex: 1;
}

.more_child_right_table_block {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-top: 20px;
}

.more_page_block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 53px;
}

.more_child_sort_hack {
  justify-content: flex-end;
}

.more_text_item_bottom {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: var(--text-color-sec);
  margin-top: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.more_hackathon_info_context {
  box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
  border-radius: 6px;
  height: 40px;
  padding: 0 14px 0 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  background: var(--login-input-back);
  color: var(--text-color);
  img {
    width: 26px;
    height: 26px;
    margin-left: 13px;
  }
}

.more_hackathon_detail_time {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  margin-top: 25px;
  color: var(--text-color-sec);
  span {
    color: var(--text-color);
  }
}

.more_award_list_item {
  display: flex;
  align-items: center;
  width: 1060px;
  height: 60px;
  background: var(--form-input-tag-back);
  border-radius: 10px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 10px;
  .more_award_list_item_title {
    width: 202px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-right: 1px solid var(--more-award-list-border);
  }
  .more_award_list_item_text {
    padding-left: 20px;
    color: var(--table-text-td);
  }
}

.soon_block {
  padding-top: 56px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
