[theme="dark"]:root {
    --back-color: #0F1114;
    --text-color: #FFFFFF;
    --swicht-back: #252A33;
    --search-back: #373F4D;
    --search-border: #656D7A;
    --search-placeholder: #FFF2F2;
    --text-color-sec: #9DA3AE;
    --value-back: #252A33;
    --other-back-color: #0F1114;
    --login-back: #0F1114;
    --login-sign-text-color: #99A0A7;
    --login-forget-text-color: #C3CBD3;
    --login_input_no_account: #C3CBD3;
    --login-input-back: #373F4D;
    --login-connect-border: #3B414D;
    --login-connect-back: #252A33;
    --login-connect-title-border: #000000;
    --login-top-text: #9DA3AE;
    --login-connect-block-back: #373F4D;
    --message-type-block: #252A33;
    --message-type-onCheck: #99A0A7;
    --message-handle-button: #373F4D;
    --message-handle-button-text: #9DA3AE;
    --message-bottom-line: #000000;
    --message-info_text: #9DA3AE;
    --pagination-total: #C3CBD3;
    --pagination-number-back: #373F4D;
    --pagination-number-text: #9DA3AE;
    --pageFooter-bottom-back: #15181D;
    --pageFooter-bottom-text: #9DA3AE;
    --pageFooter-bottom-placeholder: #FFFFFF;
    --page_bottom_copyright: #5D5D5D;
    --form-input-border: #555C66;
    --form-input-textarea: #555C66;
    --form-input-textarea-border: #555C66;
    --form-input-tag-add: #FAE6FF;
    --form-input-tag-back: #252A33;
    --form-input-tag-text: #9DA3AE;
    --radio-border: #C3CBD3;
    --radio-label: #FFFFFF;
    --coin-back: #252A33;
    --token-abbreviation: #E9D2FF;
    --file-border: #555C66;
    --file-title: #9DA3AE;
    --table-border: #323841;
    --table-input-border: #454E5D;
    --table-input-back: #373F4D;
    --project-left-border: #99A0A7;
    --project-left-top-sort-border: #9DA3AE;
    --project-left-top-sort-back: #373F4D;
    --project-table-tool-border: #9DA3AE;
    --project-table-tool-back: #555C66;
    --project-table-item: #555C66;
    --clear-all-btn-back: #555C66;
    --clear-all-btn-border: #9DA3AE;
    --tag-btn-border: #9DA3AE;
    --tag-btn-back: #555C66;
    --router-line-color: #FE91FF;
    --handle-btn-back: #222730;
    --project-info-line: #9DA3AE;
    --time-line-border: #9DA3AE;
    --time-line-time-color: #9DA3AE;
    --investor-border: #373F4D;
    --investor-text-color: #9DA3AE;
    --investor-table-border: #3B414A;
    --project-review-avatar: #9DA3AE;
    --project-review-border: #373F4D;
    --project-review-text: #9DA3AE;
    --project-review-load-back: #252A33;
    --project-detail-contributor-back: #252A33;
    --comment-btn-border: #9DA3AE;
    --project-textarea-back: #252A33;
    --project-dialog-cancle-back: #252A33;
    --table-text-td: #FFFFFF;
    --project-update-btn-back: #252A33;
    --project-update-btn-border: #252A33;
    --project-update-text: #252A33;
    --investors-table-bottom-border: #9DA3AE;
    --investors-table-bottom-title-text: #9DA3AE;
    --investors-tag-border: #9DA3AE;
    --overview-border: #252A33;
    --people-border: #555C66;
    --people-tag-back: #9DA3AE;
    --people-tag-color: #252A33;
    --people-detail-work-border: #252A33;
    --meeting-border: #373F4D;
    --kol-back-color1: #353D4A;
    --kol-back-color2: #252A33;
    --more-tag-check-back: #FAE6FF;
    --more-award-list-border: #555C66;
    --user-check-border: #EA9BFF;
    --user-check-back: #373F4D;
    --user-google-back: #373F4D;
    --user-google-line: #99A0A7;
    --wiki-rules-border: #454D5D;
    --table-white-back: #555C66;
    --table-kol-text: #9DA3AE;
    --user-like-block: #373F4D;
    --search-right-back-hover: #9DA3AE;
    --search-right-back: #373F4D;
    --search-right-content: #9DA3AE;
    --search-right-content-hover: #252A33;
    --search-text: #EA9BFF;
    --lang-btn: #C3CBD3;
    --wiki-block-border: #4E5664;
}
