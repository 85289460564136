:root {
    --back-color: #FFFFFF;
    --text-color: #000000;
    --swicht-back: #ECECEC;
    --search-back: #F3F3F9;
    --search-border: #E4DEEC;
    --search-placeholder: #A5A5A5;
    --text-color-sec: #5D5D5D;
    --value-back: #F5F6F8;
    --other-back-color: #F9F9F9;
    --login-back: #FFFFFF;
    --login-sign-text-color: #999999;
    --login-forget-text-color: #8A8A8A;
    --login_input_no_account: #000000;
    --login-input-back: #FFFFFF;
    --login-connect-border: #DFDFDF;
    --login-connect-back: #FFFFFF;
    --login-connect-title-border: #F3F3F9;
    --login-top-text: #7D7D7D;
    --login-connect-block-back: #F3F3F9;
    --message-type-block: #FFFFFF;
    --message-type-onCheck: #7D7D7D;
    --message-handle-button: #F3F3F9;
    --message-handle-button-text: #7D7D7D;
    --message-bottom-line: #F0F0F0;
    --message-info_text: #999999;
    --pagination-total: #5D5D5D;
    --pagination-number-back: #ECECEC;
    --pagination-number-text: #000000;
    --pageFooter-bottom-back: #F5F5F5;
    --pageFooter-bottom-text: #5D5D5D;
    --pageFooter-bottom-placeholder: #B2B2B2;
    --page_bottom_copyright: #989898;
    --form-input-border: #E6E6E6;
    --form-input-textarea: #FFFFFF;
    --form-input-textarea-border: #E6E6E6;
    --form-input-tag-add: #200E32;
    --form-input-tag-back: #F3F3F9;
    --form-input-tag-text: #5D5D5D;
    --radio-border: #E0E0E0;
    --radio-label: #200E32;
    --coin-back: #F3F3F9;
    --token-abbreviation: #200E32;
    --file-border: #E4DEEC;
    --file-title: #979797;
    --table-border: #E6E6E6;
    --table-input-border: #E6E6E6;
    --table-input-back: #454E5D;
    --project-left-border: #D2D2D2;
    --project-left-top-sort-border: #D9D9D9;
    --project-left-top-sort-back: #F6F6F6;
    --project-table-tool-border: #D2D2D2;
    --project-table-tool-back: #F2F2F2;
    --project-table-item: #EDEDED;
    --clear-all-btn-back: #F6F6F6;
    --clear-all-btn-border: #D9D9D9;
    --tag-btn-border: #FFFFFF;
    --tag-btn-back: #FFFFFF;
    --router-line-color: #B034B7;
    --handle-btn-back: #FFFFFF;
    --project-info-line: #E1E1E1;
    --time-line-border: #DFDFDF;
    --time-line-time-color: #A5A5A5;
    --investor-border: #E4DEEC;
    --investor-text-color: #5D5D5D;
    --investor-table-border: #E6E6E6;
    --project-review-avatar: #E9E9E9;
    --project-review-border: #E8E8E8;
    --project-review-text: #2B2B2B;
    --project-review-load-back: #F3F3F3;
    --project-detail-contributor-back: #F9F9F9;
    --comment-btn-border: #E6E6E6;
    --project-textarea-back: #F6F6F6;
    --project-dialog-cancle-back: #565656;
    --table-text-td: #5D5D5D;
    --project-update-btn-back: #F6F6F6;
    --project-update-btn-border: #D9D9D9;
    --project-update-text: #5D5D5D;
    --investors-table-bottom-border: #E8E8E8;
    --investors-table-bottom-title-text: #262626;
    --investors-tag-border: #E0E0E0;
    --overview-border: #E0E0E0;
    --people-border: #E1E2E6;
    --people-tag-back:#F3F3F9;
    --people-tag-color: #999999;
    --people-detail-work-border: #E4DEEC;
    --meeting-border: #E1E2E6;
    --kol-back-color1: #FFF9F8;
    --kol-back-color2: #FBF2FF;
    --more-tag-check-back: #FFF5FD;
    --more-award-list-border: #DFDFDF;
    --user-check-border: #B034B7;
    --user-check-back: #FFF5FD;
    --user-google-back: #FBFBFB;
    --user-google-line: #707070;
    --wiki-rules-border: #DEDEDE;
    --table-white-back: #F9F9F9;
    --table-kol-text: #5D5D5D;
    --user-like-block: #FAF6FF;
    --search-right-back-hover: #EEEEEE;
    --search-right-back: #F9F9F9;
    --search-right-content: #5D5D5D;
    --search-right-content-hover: #5D5D5D;
    --search-text: #000000;
    --lang-btn: #F9F9F9;
    --wiki-block-border: #FFFFFF;
}
