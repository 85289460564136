.included_title {
  width: 1190px;
  font-size: 60px;
  font-weight: bold;
  color: var(--text-color);
  margin: 30px 0 27px;
  text-align: center;
}
.included_title_other {
  width: 1270px !important;
}

.included_text {
  width: 1190px;
  font-size: 16px;
  font-weight: 400;
  color: var(--message-handle-button-text);
  margin-bottom: 40px;
  text-align: center;
  line-height: 28px;
}

.included_form {
  width: 1388px;
}

.included_upload_logo {
  width: 100px;
  height: 100px;
  background: #F3F3F9;
  border-radius: 8px;
  opacity: 1;
  border: 1px solid #E4DEEC;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 20px;
    height: 20px;
  }
}

.included_label_title {
  font-size: 16px;
  font-weight: bold;
  color: var(--text-color);
}

.included_label_text {
  font-size: 14px;
  font-weight: 400;
  color: var(--pageFooter-bottom-text);
  margin-left: 8px;
}

.included_upload_logo_limit {
  font-size: 12px;
  font-weight: 400;
  color: var(--pageFooter-bottom-text);
  margin-top: 8px;
}

.included_form_input {
  min-height: 50px;
  border-radius: 10px;
  border: 1px solid var(--form-input-border);
  background: var(--back-color);
  color: var(--text-color);
}

.included_form_datepicker {
  width: 600px;
}

.included_form_datepicker_event {
  width: 237px;
}

.included_form_input:focus {
  border: 1px solid #E9B8EF;
  box-shadow: 0 0 0 4px rgba(241,212,255,0.33);
  background: var(--back-color) !important;
}

.included_form_input:hover {
  border: 1px solid #E9B8EF;
  background: var(--back-color);
}

.included_form_input::placeholder {
  color: var(--pageFooter-bottom-placeholder) !important;
}

.included_form_textarea > textarea {
  border: 1px solid var(--form-input-textarea-border) !important;
  background: var(--form-input-textarea) !important;
}

.included_form_textarea > textarea:focus {
  background: var(--form-input-textarea) !important;
  color: var(--text-color);
}

.included_form_add_input {
  width: 750px;
}

.ant-input-outlined:focus-within {
  border: none !important;
  box-shadow: 0 0 0 4px rgba(241,212,255,0.33);
  background: var(--back-color);
}
.included_form_tag_input {
  display: flex;
  align-items: center;
  gap: 20px;
}

.included_form_tag_add_button {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;
  font-weight: bold;
  color: var(--form-input-tag-add);
  cursor: pointer;
}

.included_tag_list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 14px;
}

.included_tag_item {
  padding: 7px 10px;
  display: block;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  border-radius: 8px;
  background: var(--form-input-tag-back);
  color: var(--form-input-tag-text);
}

.included_tag_item_close {
  width: 14px;
  height: 14px;
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
}

.ant-picker-outlined:focus-within {
  border: none !important;
  box-shadow: 0 0 0 4px rgba(241,212,255,0.33);
  background: var(--back-color);
}

.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: none;
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  @extend .included_form_input
}

.ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border: 1px solid #E9B8EF;
  background: var(--back-color);
}

.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector:focus {
  border: 1px solid #E9B8EF;
  box-shadow: 0 0 0 4px rgba(241,212,255,0.33);
  background: var(--back-color) !important;
}

.included_media_item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.included_media_link_input {
  width: 512px;
}

.included_media_add {
  margin-top: 20px;
}

.included_form_white_paper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.included_upload_button {
  cursor: pointer;
  padding: 0 12px;
  height: 50px  ;
  background: #F3F3F9;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 14px;
  font-weight: 500;
  color: #200E32;
}

.included_form_coin_block {
  width: 1380px;
  background: var(--coin-back);
  border: 1px solid var(--form-input-border);
  border-radius: 10px;
  margin-top: 16px;
  padding: 13px 24px 18px;
  box-sizing: border-box;
}

.included_form_coin_tokenAdd {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  span {
    font-size: 14px;
    font-weight: 500;
    color: var(--token-abbreviation);
    margin-right: 11px;
  }
}

.included_form_coin_Price {
  margin-bottom: 0;
  margin-top: 30px;
  margin-left: 24px;
}

.included_form_token_abbreviation {
  width: 320px;
}

.included_form_token_public {
  width: 290px;
}

.included_form_input_suffix_icon {
  font-size: 18px;
  font-weight: 400;
  color: #200E32;
}

.included_form_audit_options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  height: 45px;
  img {
    width: 16px;
    height: 16px;
  }
  .included_form_audit_options_left {
    display: flex;
    align-items: center;
    gap: 7px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.included_form_audit_options_node {
  display: flex;
  align-items: center;
  gap: 7px;
  font-family: Montserrat,'PingFang',sans-serif;
  img {
    width: 24px;
    height: 24px;
  }
}

.included_form_audit_form {
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .included_form_audit_other {
    display: flex;
    align-items: flex-end;
    .included_form_audit_other_input {
      width: 390px;
      margin-right: 10px;
    }
    .included_upload_toast {
      font-size: 12px;
      font-weight: 500;
      color: #5D5D5D;
      font-family: Montserrat,'PingFang',sans-serif;
      margin-left: 10px;
    }
  }
}

.included_form_audit_pdf_input {
  width: 390px;
}

.included_form_audit_upload {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0;
}

.included_form_audit_time {
  width: 330px;
}

.included_form_upload_file {
  display: flex;
  align-items: center;
  gap: 27px;
  .included_form_upload_file_item {
    height: 50px;
    display: flex;
    align-items: center;
    gap: 11px;
    padding: 0 7px;
    min-width: 166px;
    border: 1px solid var(--file-border);
    border-radius: 8px;
    background: var(--coin-back);
    color: var(--file-title);
    position: relative;
  }
}

.included_form_team_list {
  display: flex;
  align-items: center;
  margin-top: 17px;
  gap: 20px;
  width: 1200px;
  flex-wrap: wrap;
  .included_form_team_list_item {
    height: 82px;
    display: flex;
    align-items: center;
    gap: 11px;
    padding: 0 70px 0 8px;
    min-width: 166px;
    border: 1px solid var(--file-border);
    border-radius: 8px;
    background: var(--coin-back);
    color: var(--file-title);
    position: relative;
    font-size: 12px;
    .included_form_team_list_item_name {
      font-weight: 500;
      font-size: 14px;
      color: var(--text-color);
      width: 120px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow-x: hidden;
    }
    .included_form_team_list_item_media {
      width: 32px;
      height: 32px;
      position: absolute;
      right: 14px;
      bottom: 6px;
    }
  }
}

.included_form_event_link {
  width: 700px;
  margin-left: 8px;
}

.included_form_event_del {
  cursor: pointer;
  margin-left: 10px;
}

.included_form_event_item {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 42px;
  &:first-child {
    margin-top: 0;
  }
}

.included_form_bottom_table {
  padding: 15px 10px;
  border-radius: 10px;
  background: var(--login-connect-back);
  border: 1px solid var(--table-border);
  .included_form_bottom_table_head_line {
    display: grid;
    grid-template-columns: 110px 180px 180px 180px 216px 180px 276px 100px;
    font-weight: bold;
    font-size: 14px;
    color: var(--text-color);
    grid-column-gap: 8px;
  }
  .included_form_bottom_table_head_line_2 {
    grid-template-columns: repeat(5,262px) !important;
  }
  .included_form_bottom_table_head_line3 {
    grid-template-columns: 262px 992px 100px;
  }
  .included_form_bottom_table_head_line_4 {
    grid-template-columns: repeat(6,1fr) !important;
  }
  .included_form_bottom_table_head_line_5 {
    grid-template-columns: 210px 210px 210px 200px 200px 200px 50px;
  }
  .included_form_bottom_table_head_line6 {
    display: grid;
    grid-template-columns: 110px 180px 180px 160px 210px 118px 190px 166px 100px;
    font-weight: bold;
    font-size: 14px;
    color: var(--text-color);
    grid-column-gap: 8px;
  }
}

.included_form_bottom_table_head {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
}

.included_form_team_list_item_content {
  width: 120px;
  word-break: break-all;
}

.included_form_table_input {
  width: 100%;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  border: 1px solid var(--table-input-border);
  background: var(--search-back);
  .ant-select-selector {
    background: var(--search-back) !important;
  }
}

.included_form_table_input:focus,.included_form_table_input:hover,.included_form_datepicker:focus,.included_form_datepicker:hover {
  background: var(--search-back);
  border: 1px solid var(--table-input-border);
}

.included_form_handler_button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 78px;
  .included_form_handler_button_left {
    display: flex;
    align-items: center;
    gap: 30px;
    div {
      width: 108px;
    }
  }
  .included_form_handler_button_item {
    cursor: pointer;
    height: 56px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    font-family: Montserrat,'PingFang',sans-serif;
    border-radius: 12px;
    text-align: center;
    line-height: 56px;
    border: none;
  }
  .included_form_handler_button_preview {
    background: #A024C5;
  }
  .included_form_handler_button_SaveDraft {
    background: #565656;
  }
  .included_form_handler_button_SubmitInclusion {
    width: 168px;
    background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
  }
}

.included_form_work_input {
  width: 400px;
}
.included_form_post_input {
  width: 275px;
}
.included_form_work_input_block {
  @extend .included_form_tag_input;
  gap: 42px;
  margin-top: 12px;
}

.included_form_work_input_title {
  margin-right: 15px;
}

.included_form_study_list_item {
  height: 82px;
  display: flex;
  align-items: center;
  gap: 11px;
  padding: 0 33px 0 8px;
  border: 1px solid var(--file-border);
  border-radius: 8px;
  background: var(--coin-back);
  color: var(--text-color);
  font-size: 14px;
  font-weight: 500;
  position: relative;
}

.included_tools_ConnectWalletTitle {
  margin-top: 60px;
  display: flex;
  align-items: center;
  gap: 30px;
}
