.project_history_block {
  width: 1566px;
  padding: 38px 0 45px;
  background: var(--back-color);
  .project_history_block_title {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .project_history_block_title_large {
    font-weight: bold;
    font-size: 24px;
    margin-right: 6px;
  }
}
.project_history_avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  border: none !important;
  width: 0;
}

.ant-table-wrapper .ant-table-thead >tr>th {
  text-align: center;
}

.ant-table-wrapper .ant-table-tbody >tr >td {
  text-align: center  ;
}

.ant-table-wrapper table {
  background: var(--back-color);
}

.ant-table-wrapper .ant-table-thead >tr>th {
  background: var(--back-color) !important;
  color: var(--text-color);
  border: none !important;
}

.ant-table-wrapper {
  border: none !important;
}

.ant-table-wrapper .ant-table-tbody >tr >td {
  border: none !important;
  font-weight: 500;
  font-size: 14px;
  color: var(--text-color);
}

.project_history_table_td {
  color: var(--table-text-td) !important;
  font-family: DIN;
}

.ant-table-wrapper .ant-table-tbody >tr {
  background: var(--form-input-tag-back);
}

.ant-table-wrapper .ant-table-tbody >tr:nth-child(2n) {
  background: var(--login-input-back) !important;
  .ant-table-cell-row-hover {
    background: var(--login-input-back) !important;
  }
}

.ant-table-wrapper .ant-table-tbody >tr:nth-child(2n-1) {
  background: var(--form-input-tag-back) !important;
  .ant-table-cell-row-hover {
    background: var(--form-input-tag-back) !important;
  }
}

.project_history_page {
  display: flex;
  justify-content: center;
  margin-top: 33px;
}
