.message_main_block {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 16px;
  padding-bottom: 30px;
  .message_type_block {
    width: 1608px;
    height: 104px;
    background: var(--message-type-block);
    border-radius: 10px;
    box-shadow: 0 0 20px 1px rgba(148,148,148,0.16);
    padding: 0 70px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    gap: 133px;
    .message_icon_item {
      display: flex;
      flex-direction: column;
      height: 100%;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0 14px;
      box-sizing: border-box;
      cursor: pointer;
      color: var(--message-type-onCheck);
      font-size: 16px;
      font-weight: 500;
      .message_icon_check {
        font-size: 16px;
        font-weight: bold;
        color: var(--text-color);
      }
    }
  }
  .message_info_list {
    width: 1608px;
    height: 710px;
    background: var(--login-connect-back);
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 36px;
    .message_info_handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      padding: 0 30px 0 25px;
      box-sizing: border-box;
      position: relative;
      .message_info_handle_left {
        display: flex;
        align-items: center;
        gap: 60px;
        color: var(--login-top-text);
        font: {
          size: 14px;
          weight: 500;
        };
        div {
          cursor: pointer;
        }
        span {
          margin-left: 4px;
          font: {
            size: 12px;
            weight: 500;
          };
        }
        .message_info_handle_left_check {
          color: var(--text-color);
          font: {
            size: 16px;
            weight: bold;
          };
        }
      }
      .message_info_handle_right {
        display: flex;
        align-items: center;
        gap: 20px;
        .message_handle_button {
          padding: 6px 16px;
          background: var(--message-handle-button);
          border-radius: 20px;
          display: flex;
          align-items: center;
          color: var(--message-handle-button-text);
          cursor: pointer;
          font: {
            size: 14px;
            weight: 500;
          };
          .delete_image {
            width: 18px;
            height: 20px;
          }
          img {
            margin-right: 9px;
            width: 22px;
            height: 22px;
          }
        }
      }
    }
  }
}

.message_bottom_line {
  width: 1528px;
  height: 1px;
  background: var(--message-bottom-line);
  position: absolute;
  bottom: 0;
  left: 25px;
}

.message_item {
  padding: 20px 55px 16px 20px;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  .message_item_info {
    margin-left: 16px;
    .message_item_title {
      font-size: 14px;
      font-weight: bold;
      color: var(--text-color);
      margin-bottom: 8px;
    }
    .message_item_content {
      font-size: 14px;
      font-weight: 400;
      color: var(--message-info_text);
      width: 1040px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 7px;
    }
    .message_item_time {
      font-size: 14px;
      font-weight: 400;
      color: var(--message-info_text);
    }
  }
}
