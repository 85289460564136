.search_page {
  margin: 0 auto;
  padding-top: 90px;
  min-height: 100%;
  display: flex;
  .search_page_left {
    margin-right: 84px;
    .search_for {
      padding: 30px 0 44px;
      color: var(--search-right-content);
      font-weight: 400;
      font-size: 16px;
      span {
        color: var(--search-text);
      }
    }
    .search_page_left_list {
      width: 100%;
      padding: 0 20px 60px 13px;
      box-sizing: border-box;
      display: grid;
      grid-template-columns: repeat(3,328px);
      justify-content: space-between;
      grid-row-gap: 20px;
    }
  }
  .search_page_right {
    .search_page_right_block {
      width: 420px;
      border-radius: 20px;
      .search_page_right_block_title {
        width: 100%;
        background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
        border-radius: 20px 20px 0 0;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        padding: 20px 16px 12px;
        box-sizing: border-box;
      }
      .search_page_right_block_list {
        width: 100%;
        padding: 18px 0 46px;
        background: var(--project-detail-contributor-back);
        .search_page_right_block_item {
          display: flex;
          align-items: center;
          padding: 16px 20px;
          cursor: pointer;
          background: var(--search-right-back);
          &:hover {
            background: var(--search-right-back-hover);
            .search_right_title {
              font-weight: bold;
              font-size: 14px;
              color: #000000;
            }
            .search_right_content {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: var(--search-right-content-hover) !important;
            }
          }
          img {
            width: 60px;
            height: 60px;
            margin-right: 15px;
            border-radius: 50%;
          }
          .search_page_right_block_item_right {
            height: 60px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .search_right_title {
              font-weight: bold;
              font-size: 14px;
            }
            .search_right_content {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              color: var(--search-right-content);
              height: 36px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow-x: hidden;
              width: 280px;
            }
          }
        }
      }
    }
  }
}

.tag-styles {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}