.login_main {
    width: 100%;
    height: 100vh;
    display: flex;
    background: var(--login-back);
    .login_left_back {
        height: 100%;
        width: 440px;
        background:  url("../../assets/image/login/login_left_back.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        display: flex;
        justify-content: flex-end;
        .login_left_top_logo {
            margin-top: 90px;
            margin-left: 28px;
            position: absolute;
            top: 40px;
            left: 28px;
            z-index: 2;
        }
        .login_left_middle_image {
            width: 418px;
            height: 492px;
            margin-top: 270px;
        }
        .login_copyright {
            position: absolute;
            bottom: 60px;
            left: 32px;
            color: #3D3D3D;
        }
    }
}

.login_input_block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 248px;
    .login_sign_type {
        display: flex;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        color: #999999;
        gap: 52px;
        margin-bottom: 25px;
        div {
            cursor: pointer;
        }
    }
    .login_sign_check {
        font-weight: bold;
        color: var(--text-color);
        display: flex;
        align-items: center;
        flex-direction: column;
        &:after {
            content: '';
            display: inline-block;
            width: 32px;
            height: 4px;
            background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
            border-radius: 64px;
            margin-top: 10px;
        }
    }
    .login_input {
        width: 528px;
        .login_input_label {
            font-size: 14px;
            font-weight: 500;
            color: var(--text-color);
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            span {
                font-size: 12px;
                font-weight: 500;
                color: var(--login-forget-text-color);
                text-decoration: underline;
                cursor: pointer;
            }
        }
        .login_div_el {
            width: 100%;
            display: flex;
            align-items: center;
            .login_input_el {
                flex: 1;
            }
            .login_email_getCode {
                width: 108px;
                height: 56px;
                background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
                border-radius: 12px;
                font-size: 14px;
                font-weight: 500;
                color: #FFFFFF;
                margin-left: 16px;
                border: none;
            }
        }
        .login_input_el {
            width: 100%;
            height: 56px;
            border-radius: 13px;
            border: 1px solid #E6E6E6;
            background: var(--login-input-back);
            &:focus {
                box-shadow: 0 0 0 2px rgba(241,212,255,0.33);
                background: #E8EBFE;
            }
        }
    }
    .login_sign_button {
        width: 528px;
        height: 56px;
        background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
        border-radius: 12px;
        border: none;
        font-size: 14px;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 60px;
        &:hover {
            color: #ffffff !important;
        }
    }
    .login_input_no_account {
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        color: var(--login_input_no_account);
        margin-bottom: 26px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 18px;
        margin-top: 10px;
        span {
            font-size: 12px;
            font-weight: 500;
            cursor: pointer;
            color: #AF33B6;
        }
    }
    .login_block_or {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        gap: 33px;
        &:after {
            content: '';
            display: inline-block;
            flex: 1;
            width: 223px;
            height: 2px;
            background: #DEDEDE;
        }
        &:before {
            content: '';
            display: inline-block;
            flex: 1;
            width: 223px;
            height: 2px;
            background: #DEDEDE;
        }
    }
    .login_ConnectWallet_button {
        width: 100%;
        height: 48px;
        border-radius: 13px;
        border: 1px solid #E6E6E6;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--back-color);
        margin-top: 37px;
        font-size: 12px;
        font-weight: 500;
        color: var(--text-color);
        cursor: pointer;
        .login_wallet_icon {
            width: 20px;
            height: 18px;
            margin-right: 7px;
        }
    }
}
.login_password_strong_block {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 25px;
    .login_password_strong {
        width: 48px;
        height: 4px;
        background: #C0C0C0;
        border-radius: 2px;
    }

}

@for $i from 0 through 2 {
    @if $i == 0 {
        .login_password_strong_color_#{$i} {
            background: #EA4335 !important;
        }
        .login_password_strong_font_#{$i} {
            color: #EA4335 !important;
        }
    } @else if $i == 1{
        .login_password_strong_color_#{$i} {
            background: #83EA35 !important;
        }
        .login_password_strong_font_#{$i} {
            color: #83EA35 !important;
        }
    } @else {
        .login_password_strong_color_#{$i} {
            background: #D235EA !important;
        }
        .login_password_strong_font_#{$i} {
            color: #D235EA !important;
        }
    }
}
.login_wallet_component {
    margin-left: 248px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.login_wallet_block {
    width: 770px;
    height: 440px;
    background: var(--login-connect-back);
    border-radius: 16px;
    border: 1px solid var(--login-connect-border);
    margin-top: 240px;
    position: relative;
    .login_wallet_close_icon {
        position: absolute;
        right: 11px;
        top: 15px;
        cursor: pointer;
    }
    .login_wallet_top {
        display: flex;
        align-items: flex-start;
        margin: 36px 30px 0;
        padding-bottom: 60px;
        border-bottom: 1px solid var(--login-connect-title-border);
        .login_wallet_top_text {
            width: 536px;
            margin-left: 23px;
            .login_top_title {
                font-size: 16px;
                font-weight: bold;
                color: var(--text-color);
                margin-top: 10px;
            }
            .login_top_text {
                font-size: 14px;
                font-weight: 400;
                color: var(--login-top-text);
                line-height: 24px;
                margin-top: 7px;
            }
        }
    }
    .login_wallet_bottom {
        margin: 43px 30px 0;
        display: grid;
        grid-template-columns: repeat(3,1fr);
        justify-content: space-between;
        grid-row-gap: 10px;
        .login_wallet_item {
            width: 232px;
            height: 90px;
            background: #F8F6F9;
            border-radius: 16px;
            border: 1px solid #F3F3F9;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            color: #000000;
            .login_wallet_item_logo {
                margin-left: 8px;
                margin-right: 10px;
            }
        }
    }
    .login_connect_wallet {
        width: calc(100% - 60px);
        margin: 43px 30px 0;
        height: 141px;
        background: var(--login-connect-block-back);
        border-radius: 16px;
        border: 1px solid #DFDFDF;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 80px;
        padding-left: 40px;
        box-sizing: border-box;
        .login_connect_title {
            font-size: 16px;
            font-weight: bold;
            color: var(--text-color);
            margin-bottom: 17px;
        }
        .login_connect_text {
            width: 300px;
            font-size: 14px;
            font-weight: 400;
            color: var(--login-top-text);
            line-height: 24px;
        }
        .login_connect_logo {
            display: flex;
            align-items: center;
            gap: 20px;
        }
    }
}
.login_return_wallet_button {
    width: 528px;
    height: 56px;
    background: linear-gradient(100deg, #FD806B 0%, #9C20C9 100%);
    border-radius: 12px;
    line-height: 56px;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    cursor: pointer;
}

.login_forget_top {
    margin-top: 170px;
    margin-bottom: 40px;
    .login_forget_title {
        font-weight: bold;
        font-size: 34px;
    }
    .login_forget_content {
        font-weight: 500;
        font-size: 20px;
        color: #5D5D5D;
        line-height: 36px;
        width: 610px;
    }
}
