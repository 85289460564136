.user_main_block {
  display: flex;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 143px;
  width: 1564px;
  .user_main_block_left {
    width: 262px;
    padding: 42px 0 48px;
    border-radius: 40px;
    background: var(--login-connect-back);
    .user_main_block_btn {
      width: 100%;
      height: 40px;
      position: relative;
      &:before {
        width: 4px;
        height: 100%;
        background: var(--user-check-border);
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 70px;
      }

      .user_main_block_btn_view {
        width: 237px;
        height: 40px;
        background: var(--user-check-back);
        margin: 0 auto;
        padding-left: 10px;
        color: var(--user-check-border);
        box-sizing: border-box;
        line-height: 40px;
        border-radius: 10px;
      }
    }
  }

  .user_main_block_btn_view {
    width: 237px;
    height: 40px;
    margin: 0 auto;
    padding-left: 10px;
    box-sizing: border-box;
    line-height: 40px;
    border-radius: 10px;
    color: var(--text-color);
  }
  .user_main_block_right {
    flex: 1;
    background: var(--login-connect-back);
    margin-left: 30px;
    padding-left: 30px;
    box-sizing: border-box;
    border-radius: 40px;
  }
}

.user_main_block_left_view {
  margin-bottom: 10px;
  cursor: pointer;
}

.profile_main_block {
  padding: 45px 0;
  .profile_main_block_title {
    font-weight: bold;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .wiki_info_rules_btn {
      padding: 14px 14px 14px 23px;
      border-radius: 23px;
      display: flex;
      align-items: center;
      border: 1px solid var(--wiki-rules-border);
      margin-right: 40px;
      font-weight: 400;
      font-size: 14px;
      img {
        width: 12px;
        height: 12px;
        margin-left: 7px;
      }
    }
  }
  .profile_main_avatar {
    display: flex;
    align-items: center;
    margin-top: 18px;
    .profile_main_avatar_block {
      width: 108px;
      height: 108px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
      border-radius: 50%;
      background: var(--login-input-back);
      img {
        width: 54px;
        height: 54px;
      }
    }
    .profile_main_avatar_image {
      width: 108px;
      height: 108px;
      box-shadow: 0 3px 6px 1px rgba(0,0,0,0.16);
      border-radius: 50%;
    }
    .profile_main_upload_avatar {
      margin-left: 12px;
      padding: 17px 18px;
      background: #FFF5FD;
      border-radius: 16px;
      font-weight: bold;
      font-size: 14px;
      color: #B034B7;
      cursor: pointer;
    }
  }
}

.profile_main_nickname {
  margin-top: 36px;
  .profile_main_nickname_title {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
    span {
      color: #FF2929;
    }
  }
  .profile_main_nickname_input {
    width: 584px;
    height: 60px;
    border-radius: 16px;
    background: var(--login-input-back);
    &:focus {
      background: var(--login-input-back) !important;
    }
  }
  .profile_main_bio_input {
    width: 584px;
    height: 257px;
    border-radius: 16px;
    background: var(--login-input-back);
    color: var(--text-color);
    margin-top: 10px;
    &:focus {
      background: var(--login-input-back) !important;
    }
  }
}

.profile_main_save {
  width: 584px;
  display: flex;
  justify-content: flex-end;
  padding: 30px 0 38px;
  div {
    padding: 20px 42px;
    background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.account_email_address_block {
  margin-top: 60px;
  .account_email_address_title {
    font-weight: 400;
    font-size: 15px;
    color: #5D5D5D;
  }
  .account_email_address {
    font-weight: bold;
    font-size: 20px;
    color: #FF5E73;
    margin-top: 13px;
  }
}

.account_main_link_btn {
  display: flex;
  align-items: center;
  margin-top: 56px;
  .account_main_link_wallet {
    padding: 13px 10px 13px 16px;
    border: 1px solid var(--user-check-border);
    color: var(--user-check-border);
    border-radius: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: var(--login-input-back);
  }
  .account_main_link_google {
    padding: 14px 34px 12px 20px;
    background: var(--user-google-back);
    display: flex;
    align-items: center;
    border-radius: 12px;
    border: 1px solid var(--form-input-border);
    margin-left: 20px;
    cursor: pointer;
    img {
      margin-left: 6px;
    }
  }
}

.account_main_save {
  display: inline-flex;
  justify-content: flex-end;
  margin: 174px 0 130px;
  padding: 20px 42px;
  background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.account_google_ver_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1146px;
  height: 200px;
  border-radius: 40px;
  background: var(--search-back);
  margin: 28px auto 20px;
  .account_google_ver_block_title {
    font-weight: bold;
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 27px;
  }
  .account_google_ver_block_text {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color-sec);
  }
}

.account_google_ver_num_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 1146px;
  height: 428px;
  border-radius: 40px;
  background: var(--search-back);
  margin: 0 auto 36px;
  .account_google_ver_num_block_title {
    font-weight: bold;
    font-size: 18px;
    color: var(--text-color);
    margin-bottom: 27px;
  }
  .account_google_ver_num_block_text {
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color-sec);
  }
  .account_google_ver_num_input {
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    .account_ver_num_input {
      width: 80px;
      height: 80px;
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      font-family: DIN;
    }
  }
  .account_google_ver_num_line {
    width: 34px;
    height: 1px;
    background: var(--user-google-line);
    margin: 0 20px;
  }
}

.account_google_ver_num_btn {
  width: 116px;
  height: 56px;
  background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  text-align: center;
  line-height: 56px;
  margin-top: 43px;
  cursor: pointer;
}

.wiki_info_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 30px;
  .wiki_info_top_info {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    img {
      width: 68px;
      height: 68px;
      margin-right: 14px;
    }
  }
}

.wiki_change_switch {
  width: 168px;
  height: 34px;
  background: var(--search-back);
  border-radius: 17px;
  display: flex;
  align-items: center;
  color: var(--text-color-sec);
  padding: 3px;
  box-sizing: border-box;
  cursor: pointer;
  div {
    flex: 1;
    text-align: center;
  }
  .wiki_check {
    width: 79px;
    height: 28px;
    background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
    border-radius: 14px;
    color: #FFFFFF;
    line-height: 28px;
  }
}

.wiki_table_block {
  width: calc(100% - 40px);
}

.friend_table_block {
  width: 937px;
  .wiki_table_title {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    padding: 34px 0 11px;
    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 14px;
      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
  .wiki_table_cell {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    height: 70px;
    background: var(--table-white-back);
    div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      font-size: 14px;
    }
    &:nth-child(2n) {
      background: var(--search-back);
    }
  }
}

.wiki_table_title {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  padding: 34px 0 11px;
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 14px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.wiki_table_cell {
  display: grid;
  grid-template-columns: repeat(4,1fr);
  height: 70px;
  background: var(--table-white-back);
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
  }
  &:nth-child(2n) {
    background: var(--search-back);
  }
}

.audit_table_cell {
  display: grid;
  grid-template-columns: repeat(7,1fr);
  height: 70px;
  background: var(--table-white-back);
  div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
  }
  &:nth-child(2n) {
    background: var(--search-back);
  }
}

.wiki_page_block {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 33px 0 39px;
}

.collection_page_block {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0 35px;
}

.collection_select {
  padding: 31px 0 32px;
  .ant-select-selector {
    height: 32px !important;
    font-weight: 400;
    font-size: 14px;
    font-family: Montserrat,'PingFang',sans-serif;
  }
}

.collection_list {
  width: calc(100% - 40px);
}

.collection_list_block {
  width: 100%;
}

.collection_list_grid_3 {
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
}

.collection_list_grid_3_small {
  display: grid;
  grid-template-columns: repeat(3,328px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  justify-content: center;
}

.collection_meeting_item {
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 3px 20px 1px rgba(168,168,168,0.16);
  border-radius: 20px;
  background: var(--login-input-back);
  position: relative;
  .collection_meeting_image {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
  }
  .collection_meeting_text {
    font-weight: bold;
    font-size: 16px;
    line-height: 30px;
    height: 60px;
    color: var(--text-color);
    padding: 0 15px;
  }
  .collection_meeting_time {
    display: flex;
    align-items: center;
    padding: 20px 15px 25px;
    font-weight: 400;
    font-size: 12px;
    .collection_meeting_time_icon {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}

.collection_kol_item {
  width: 100%;
  padding: 28px 25px 13px;
  box-sizing: border-box;
  box-shadow: 0 3px 20px 1px rgba(168,168,168,0.16);
  border-radius: 20px;
  background: var(--login-input-back);
  position: relative;
  .collection_kol_item_top {
    display: flex;
    align-items: center;
    .collection_kol_item_avatar {
      width: 100px;
      height: 100px;
    }
    .collection_kol_item_info {
      margin-left: 20px;
      color: var(--table-kol-text);
      .collection_kol_item_name {
        display: flex;
        align-items: center;
        color: var(--text-color);
        img {
          width: 48px;
          height: 48px;
          margin-left: 5px;
        }
      }
    }
  }
}

.collection_kol_item_bottom {
  font-family: PingFang;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: var(--table-kol-text);
  margin-top: 19px;
}

.collection_list_grid_4 {
  display: grid;
  grid-template-columns: repeat(4,246px);
  justify-content: center;
  grid-row-gap: 20px;
  grid-column-gap: 26px;
}

.collection_people_list_character_list_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  border: 1px solid var(--people-border);
  box-shadow: 0 0 20px 1px rgba(167,167,167,0.16);
  border-radius: 12px;
  padding: 20px 0;
  background: var(--login-connect-back);
  cursor: pointer;
  position: relative;
  .collection_people_list_popular_figures_item_img {
    width: 120px;
    height: 120px;
    margin-bottom: 15px;
    border-radius: 50%;
  }
  .collection_people_list_popular_figures_item_name {
    font-weight: bold;
    font-size: 16px;
    color: var(--text-color);
  }
  .collection_people_list_popular_figures_item_video_img {
    padding: 8px 0 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 34px;
      height: 34px;
    }
  }
  .collection_people_list_popular_figures_item_tag {
    font-weight: bold;
    font-size: 14px;
    color: #B034B7;
    margin-bottom: 8px;
  }
  .collection_people_list_popular_figures_item_tag2 {
    padding: 6px 17px;
    background: var(--people-tag-back);
    border-radius: 15px;
    font-weight: 400;
    font-size: 14px;
    color: var(--people-tag-color);
  }
}

.collection_project_table {
  padding: 36px 17px;
}

.collection_main_like_block {
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  padding: 16px 0 18px;
  background: var(--user-like-block);
  margin-top: 30px;
  border-radius: 16px;
  .collection_main_like_icon {
    width: 42px;
    height: 42px;
  }
  .collection_main_like_name {
    font-weight: 500;
    font-size: 16px;
    color: var(--text-color);
    padding: 7px 0 6px;
  }
  .collection_main_like_number {
    font-family: DIN;
    font-weight: 500;
    font-size: 14px;
    color: var(--text-color-sec);
  }
}

.collection_main_like_block {
  &~.collection_select {
    padding-top: 20px;
  }
}

.collection_main_like_block_all {
  width: 162px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding-right: 10px;
  &::after {
    width: 0px;
    height: 53px;
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    border-right: 1px solid #D7CAE8;
  }
}

.collection_main_like_block_type {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(6,1fr);
  .collection_main_like_block_type_child {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.draft_operate_icon {
  display: flex;
  gap: 70px;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.audit_operate_icon {
  display: flex;
  gap: 30px;
  align-items: center;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.draft_del_dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 514px;
  background: var(--tag-btn-border);
  border-radius: 30px;
  border: 1px solid #707070;
  position: relative;
  .project_comment_dialog_close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
  }
  .project_comment_dialog_title {
    padding-top: 77px;
    font-weight: bold;
    font-size: 18px;
  }
  .project_handle_btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 90px;
    gap: 120px;
    div {
      padding: 20px 30px;
      background: var(--project-dialog-cancle-back);
      color: #FFFFFF;
      border-radius: 12px;
      cursor: pointer;
    }
    .project_handle_btn_confirm {
      background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
    }
  }
}

.submission_audit_image {
  position: absolute;
  top: 0;
  right: 0;
}

.password_main_save {
  display: inline-flex;
  justify-content: flex-end;
  margin: 125px 0 148px;
  padding: 20px 42px;
  background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  cursor: pointer;
}

.project_comment_dialog_text {
  font-weight: 400;
  font-size: 14px;
  width: 523px;
  max-height: 180px;
  margin: 17px auto 10px;
  line-height: 20px;
  word-break: break-all;
  overflow-y: scroll;
}

.friend_invite_link {
  width: 937px;
  height: 60px;
  border-radius: 16px;
  font-weight: bold !important;
  font-size: 16px !important;
  font-family: Montserrat !important;
}


.ant-input-outlined .ant-input-disabled {
  color: var(--text-color) !important;
}

.friend_invite_link_copy_icon {
  width: 24px;
  height: 24px;
  margin-right: 20px;
  cursor: pointer;
}

.friend_save_btn {
  width: 937px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px 0 50px;
  .friend_main_save {
    display: inline-flex;
    justify-content: flex-end;
    padding: 20px 42px;
    background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    cursor: pointer;
  }
}

.wiki_main_block {
  width: 692px;
  box-shadow: 0 0 20px 1px rgba(182,182,182,0.16);
  border-radius: 20px;
  border: 1px solid var(--wiki-block-border);
  background: var(--search-back);
  padding: 22px 27px 26px 32px;
  box-sizing: border-box;
  margin: 108px auto 260px;
  .wiki_main_title {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 30px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
  }
  .wiki_balance {
    font-weight: bold;
    font-size: 30px;
  }
  .wiki_balance_freeze {
    font-weight: 400;
    font-size: 16px;
    color: #FF2929;
    margin-top: 17px;
  }
  .wiki_handle_btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    div {
      width: 134px;
      height: 46px;
      border-radius: 12px;
      color: #ffffff;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      line-height: 46px;
      cursor: pointer;
      &:first-child {
        background: linear-gradient( 100deg, #FD806B 0%, #9C20C9 100%);
      }
      &:last-child {
        background: #62B968;
        margin-left: 22px;
      }
    }
  }
}

.wiki_stake_input {
  width: 450px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 18px;
}

.wiki_stake_input:hover {
  background: var(--project-textarea-back);
}

.wiki_user_balance {
  margin-left: 25px;
  margin-bottom: 14px;
}

.contrastBlock {
  width: 100%;
  display: flex;
  align-items: flex-start;
  .contrastBlock_item {
    width: 50%;
    height: 100%;
    overflow-x: scroll;
    position: relative;
    z-index: 2;
    .contrastBlock_item_text{
      width: 50%;
      height: 40px;
      padding-left: 20px;
      font-size: 18px;
      font-weight: bold;
      box-sizing: border-box;
      background: var(--search-back);
      position: fixed;
      z-index: 1;
      line-height: 40px;
      border-right: 1px solid #999999;
    }
  }
  .contrastBlock_left {
    border-right: 1px solid #999999;
    .contrastBlock_item_text {
      border-right: 1px solid #999999;
    }
  }
}

.audit_claim_text {
  margin-left: 6px;
  font-size: 10px;
  font-weight: lighter;
  cursor: pointer;
}

.audit_table_title {
  width: 100px !important;
  display: block !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  //line-height: 70px;
  text-align: center;
  padding-left: 10px;
  box-sizing: border-box;
}
