.people_university_banner {
  width: 100%;
  height: 300px;
  position: relative;
  margin-bottom: 20px;
  .people_university_banner_img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .people_university_info {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 50%;
    transform: translateY(-50%);
    img {
      width: 160px;
      height: 160px;
      margin-bottom: 20px;
    }
    .people_university_name {
      font-weight: bold;
      font-size: 30px;
      color: #FFFFFF;
    }
  }
}

.people_list_page_block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 38px 0 52px;
}
