* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'DIN';
  src: url('./assets/font/DIN-Regular.otf');
}

@font-face {
  font-family: 'Montserrat';
  src: url("./assets/font/Montserrat-Regular.otf");
}

@font-face {
  font-family: 'PingFang';
  src: url("./assets/font/pingfang.ttf");
}

body {
  font-family: Montserrat,'PingFang',sans-serif;
  font-size: 14px;
  color: var(--text-color);
}

:root {
  --login-left-back-top: rgba(253, 128, 107, .17);
  --login-left-back-bottom: rgba(156, 32, 201, .17)
}

.main_back {
  width: 100%;
  height: 100%;
  background-color: var(--other-back-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  position: relative;
}

.main_back_white {
  background-color: var(--back-color) !important;
}

.message-success  .ant-message-notice-content {
  background-color: #00BE82 !important;
}

.message-fail .ant-message-notice-content {
  background-color: #F35757 !important;
}

.ant-message .ant-message-notice-wrapper .ant-message-notice-content .ant-message-custom-content {
  display: flex;
  align-items: center;
}

.content-msg {
  font-size: 14px;
  font-family: Montserrat,'PingFang',sans-serif;
  font-weight: 400;
  color: #FFFFFF;
}

.ant-input-textarea-show-count .ant-input-data-count {
  bottom: 10px;
  right: 12px;
  font-family: Montserrat,'PingFang',sans-serif;
}

.ant-input-suffix {
  font-family: Montserrat,'PingFang',sans-serif;
}

.content-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}

a {
  text-decoration: none !important;
}

.flex {
  display: flex;
}

.pb-60 {
  padding-bottom: 60px;
}

.Dropdown_tag_text {
  font-weight: 500;
  font-size: 12px;
  font-family: Montserrat,'PingFang',sans-serif;
}

.ant-dropdown .ant-dropdown-menu {
  background-color: var(--tag-btn-border) !important;
  width: 150px;
}

.ant-input-textarea-show-count .ant-input-data-count {
  color: var(--text-color);
}

.ant-input-textarea-affix-wrapper.ant-input-affix-wrapper >textarea.ant-input {
  color: var(--text-color);
}

.ant-upload-list {
  display: none !important;
}

.ant-upload-wrapper {
  height: 50px;
}

.text-overflow-style {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-overflow-line-2-style {
  overflow: hidden;
  text-overflow: ellipsis;  /* 超出部分省略号 */
  word-break: break-all;  /* break-all(允许在单词内换行。) */
  display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
  -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-line-clamp: 2; /* 显示的行数 */
}

.text-overflow-line-3-style {
  overflow: hidden;
  text-overflow: ellipsis;  /* 超出部分省略号 */
  word-break: break-all;  /* break-all(允许在单词内换行。) */
  display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
  -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-line-clamp: 3; /* 显示的行数 */
}

.text-overflow-line-4-style {
  overflow: hidden;
  text-overflow: ellipsis;  /* 超出部分省略号 */
  word-break: break-all;  /* break-all(允许在单词内换行。) */
  display: -webkit-box; /* 对象作为伸缩盒子模型显示 */
  -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
  -webkit-line-clamp: 4; /* 显示的行数 */
}

.ant-message {
  z-index: 6000;
}

.ant-spin-fullscreen {
  z-index: 7000;
}
::-webkit-scrollbar {
   width: 4px;
   height: 5px;
   border-radius: 10px;
}
::-webkit-scrollbar-thumb {
   border-radius: 10px;
   background-color: #999999;
   height: 50px;
   width: 50px;
}
::-webkit-scrollbar-track {
   border-radius: 10px;
   background-color: #fff;
}

.rotateY-180 {
  transform: rotateY(180deg);
}
